import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'


class Ofertasidebar extends React.Component {
    render() {
      return (
     <>
     <div class="goingtofixed">
        <div className='services'>
          <div className='inner'>
            <div className='sidebar-title-cont'>
              <h4 className='sidebar-title'>ALL SERVICES</h4>
            </div>
              <ul>
              {this.props.aktywny === 'strony-internetowe' ?   
              <li className='active'><Link to='/en/website-development'>Website development<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/en/website-development'>Website development<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'sklepy-internetowe' ?   
              <li className='active'><Link to='/en/online-shops'>online shops<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/en/online-shops'>online shops<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'aplikacje-webowe' ?   
              <li className='active'><Link to='/en/web-apps'>web apps<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/en/web-apps'>web apps<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'grafika-komputerowa' ?   
              <li className='active'><Link to='/en/computer-graphics'>computer graphics<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/en/computer-graphics'>computer graphics<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'marketing-i-reklama' ?   
              <li className='active'><Link to='/en/marketing-and-advertising'>marketing and advertising<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/en/marketing-and-advertising'>marketing and advertising<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'audyty' ?   
              <li className='active'><Link to='/en/audits'>audits<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/en/audits'>audits<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}
              </ul>
          </div>
        </div>
        <div className='call-up'>
          <div className='inner'>
            <div className='title-cont'>
              <h4 className='title'>NEED OUR HELP?</h4>
            </div>
            <div className='text-cont'>
              <p className='text'>You don't like filling forms? Call us, and we will try to answer your questions.</p>
            </div>
            <div className='phone'>
              <a href=''>
              <FontAwesomeIcon className='icon' icon={faPhoneAlt} />
              +48 730 903 403
              </a>
            </div>
          </div>
        </div>
        </div>
      </>
      );
    }
  }
export default Ofertasidebar;
