import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'


class Ofertasidebar extends React.Component {
    render() {
      return (
     <>
     <div class="goingtofixed">
        <div className='services'>
          <div className='inner'>
            <div className='sidebar-title-cont'>
              <h4 className='sidebar-title'>WSZYSTKIE OFERTY</h4>
            </div>
              <ul>
              {this.props.aktywny === 'strony-internetowe' ?   
              <li className='active'><Link to='/strony-internetowe'>Strony Internetowe<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/strony-internetowe'>Strony Internetowe<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'sklepy-internetowe' ?   
              <li className='active'><Link to='/sklepy-internetowe'>Sklepy Internetowe<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/sklepy-internetowe'>Sklepy Internetowe<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'aplikacje-webowe' ?   
              <li className='active'><Link to='/aplikacje-webowe'>Aplikacje Webowe<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/aplikacje-webowe'>Aplikacje Webowe<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'grafika-komputerowa' ?   
              <li className='active'><Link to='/grafika-komputerowa'>Grafika Komputerowa<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/grafika-komputerowa'>Grafika Komputerowa<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'marketing-i-reklama' ?   
              <li className='active'><Link to='/marketing-i-reklama'>Marketing i Reklama<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/marketing-i-reklama'>Marketing i Reklama<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}

              {this.props.aktywny === 'audyty' ?   
              <li className='active'><Link to='/audyty'>Audyty<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li> :   
              <li><Link to='/audyty'>Audyty<FontAwesomeIcon className='icon-arr' icon={faChevronRight}/> </Link></li>}
              </ul>
          </div>
        </div>
        <div className='call-up'>
          <div className='inner'>
            <div className='title-cont'>
              <h4 className='title'>POTRZEBUJESZ NASZEJ POMOCY?</h4>
            </div>
            <div className='text-cont'>
              <p className='text'>Nie lubisz wypełniania formularzy? Zadzwoń do nas, postaramy się odpowiedzieć na twoje pytania.</p>
            </div>
            <div className='phone'>
              <a href=''>
              <FontAwesomeIcon className='icon' icon={faPhoneAlt} />
              +48 730 903 403
              </a>
            </div>
          </div>
        </div>
        </div>
      </>
      );
    }
  }
export default Ofertasidebar;
